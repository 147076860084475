<!--
 * @Descripttion:
 * @version:
 * @Author: 李波
 * @Date: 2020-01-11 20:18:57
 * @LastEditors  : 李波
 * @LastEditTime : 2020-01-21 14:06:37
 -->
<template>
  <div class="content">
    <!-- 头部 -->
    <div class="head">
      <div class="search">
        <div class="search-bom">
          <md-field>
            <md-field-item
              name="name"
              title="日期"
              arrow="arrow-right"
              align="right"
              :content="datePickerValue"
              @click.native="isShowData2 = true">
            </md-field-item>
          </md-field>
          <md-date-picker
            ref="datePicker"
            v-model="isShowData2"
            type="custom"
            title="选择日期"
            is-twelve-hours
            :custom-types="['yyyy','MM']"
            :default-date="currentDate"
            @confirm="onDatePickerConfirm"
          ></md-date-picker>
        </div>
      </div>
    <!-- 表格 -->
      <div class="datum">
        <div class="datum_data">
          <ul>
              <li>
                  <h3>{{ man.zrl }}</h3>
                  <p>总人力</p>
              </li>
              <li>
                  <h3>{{ man.lzrl }}</h3>
                  <p>离职人力</p>
              </li>
              <li>
                  <h3>{{ man.dyxz }}</h3>
                  <p>当月新增</p>
              </li>
          </ul>
          <ul>
              <li>
                  <h3>{{ man.cdrl }}</h3>
                  <p>出单人力</p>
              </li>
              <li>
                  <h3>{{ man.hdrl }}</h3>
                  <p>活动人力</p>
              </li>
              <li>
                  <h3>{{ man.cqrl }}</h3>
                  <p>出勤人力</p>
              </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 图表 -->
    <div class="content">
        <div class="menu_box menu_buttom">
            <div class="title">
                <!-- <span class="blod"></span> -->
                <span class="span">机构占比</span>
            </div>
            <div class="menu">
               <ve-ring :data="chartData" width="340px"  :settings="chartSettings" :extend="tjextend" height="400px"></ve-ring>
            </div>
        </div>
        <div class="menu_box menu_buttom">
            <div class="title">
                <!-- <span class="blod"></span> -->
                <span class="span">机构排名</span>
            </div>
            <div class="menu">
                  <ve-bar :data="chartData" :settings="chartSettings" :extend="extend" width="340px" height="400px"></ve-bar>
            </div>
        </div>
    </div>
    <div class="menu_box">
            <div class="title">
                <!-- <span class="blod"></span> -->
                <span class="span">人力详情</span>
            </div>
            <div class="balance_radius">
              <table>
                <tr class="tr_background">
                  <th class="th_public balance">机构名称</th>
                  <th class="th_public balance">总人力</th>
                  <th class="th_public balance">当月新增</th>
                  <th class="th_public balance">离职人力</th>
                </tr>
                <tr v-for="(item,index) in manccc" :key="index">
                  <td class="balance_age">{{ item.tjcodename }}</td>
                  <td class="balance_age">{{ item.zrl }}</td>
                  <td class="balance_age">{{ item.dyxz }}</td>
                  <td class="balance_age">{{ item.lzrl }}</td>
                </tr>
                <tr v-if="manccc.length === 0" class="nodata">
                  <p>暂无数据</p>
                </tr>
              </table>
            </div>
        </div>
  </div>
</template>

<script>
import { RadioBox, RadioGroup, Toast } from 'mand-mobile'
import { manpower } from '@/api/smarkboard/manpower/index'
export default {
  data () {
    this.chartSettings = {
      labelMap: {
        dyxz: '当月新增',
        lzrl: '离职人力',
        rlzb: '人力占比',
        zrl: '总人力',
        // tjcode: '机构号',
        tjcodename: '机构名称'
      }
    }
    this.tjextend = {
      legend: {
        type: 'scroll',
        orient: 'vertical',
        right: 0,
        top: 10,
        bottom: 20
      },
      grid: {
        right: 100
      }
    }
    this.extend = {
      'xAxis.0.axisLabel.rotate': 25
      // 'yAxis.0.axisLabel.rotate': 60
    }
    return {
      currentDate: new Date(),
      isShowData2: false,
      chartData: {
        columns: ['tjcodename', 'dyxz', 'lzrl', 'rlzb', 'zrl'],
        rows: []
      },
      current: 1,
      items: [
        { name: 1, label: '月' }
      ],
      isDatePickerShow: false,
      datePickerValue: '',
      month: '',
      times: '',
      manccc: [],
      man: {}

    }
  },
  created () {
    var d = new Date()
    var year = d.getFullYear()
    var month = (d.getMonth() + 1)
    this.datePickerValue = `${year}年${month}月`
    this.month = `${year}-0${month}`
    this.getData()
  },
  methods: {
    // 获取试图数据
    async getData (isInit = true) {
      let data = { month: this.month }
      let res = await manpower(data)
      this.man = res.data.data.man
      this.manccc = res.data.data.manccc
      this.chartData.rows = this.manccc
    },
    selectDate (val) {
      this.select = val
      var d = new Date()
      var year = d.getFullYear()
      var month = (d.getMonth() + 1)
      this.yearmon = `${year}-${month}`
      this.showyearmon = `${year}年${month}月`
      this.getData()
    },
    showChangeDate () {
      this.isShowData2 = true
    },
    onDatePickerConfirm (columnsValue) {
      let aaa = []
      let bbb = []
      for (var i = 0; i < columnsValue.length; i++) {
        columnsValue[i].value > 9 ? columnsValue[i].value : columnsValue[i].value = '0' + columnsValue[i].value
        aaa.push(columnsValue[i].text)
        bbb.push(columnsValue[i].value)
      }
      this.datePickerValue = aaa.join('')
      // if (bbb.join('-') <= 9) {
      //   this.month = '0' + bbb.join('-')
      // } else {
      this.month = bbb.join('-')
      // }
      this.getData()
    }
  }
}
</script>

<style lang="stylus" scoped>
 bg-color = #ffffff;

  .container {
      height: 100%;
      overflow-y: auto;
  }
  .head {
      background-color: #F6F6FA;
      // padding-bottom: 30px;
      .search{
        border-bottom: 0.2rem solid #eee
        .search-bom{
          background-color #ffffff
          width 100vw
          height 150px
          margin 0 auto 0.3rem
        }
      }
      .tabbar{
        width:100%
        margin 0 5%
      }
      /deep/.md-tab-bar {
      //  width: 90vw !important;
       padding 0 !important
       margin 0 auto !important
      }
      /deep/ .md-tab-bar-list{
              .md-tab-bar-item{
                color black
                background #FFFFFF
              border:1px solid #EEEEEE
            }
            .is-active{
              background #E6F2FC
              color #3AABE5
              border:1px solid #63B5E3
            }
            }
   /deep/ .md-tab-bar-ink{
      display none
    }
    .datum{
      height:400px;
      padding: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      background: bg-color;
      border-bottom: 0.2rem solid #eee
      .datum_data{
      width: 100%;
      height: 100%;
        ul{
          display: flex;
          height:50%;
          padding-top:0.3rem;
          li{
            width:33%;
            text-align:center;
            align-items:center;
            p{
              font-size: 0.3rem;
              color: #989898;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
.content {
    background-color: #F6F6FA;
    .menu_box {
        padding: 20px 25px;
        // margin-bottom: 30px;
        background-color: bg-color;
        .title{
          padding 30px 80px
          border-bottom 1px solid #EEEEEE
          .span{
            font-size:48px;
            font-family:PingFang SC;
            font-weight:bold;
            color:rgba(61,61,61,1);
          }
        }
    }
    .menu_buttom{
        border-bottom: 0.2rem solid #eee
    }
    .menu {
        margin-top: 0.5rem;
        margin-bottom: 20px;
    }
    /*修改标题左边标识的样式*/
    .blod {
        height: 40px;
        width: 12px;
        background: #4188F2;
        display: inline-block;
        vertical-align: -6px;
        margin-right: 10px;
    }
     .tabbar{
        width:100%
        margin 0 5%
      }
      /deep/.md-tab-bar {
       width: 40vw !important;
       padding 0 !important
       margin 0 auto !important
      }
      /deep/ .md-tab-bar-list{
              .md-tab-bar-item{
                color black
                background #FFFFFF
              border:1px solid #EEEEEE
            }
            .is-active{
              background #E6F2FC
              color #3AABE5
              border:1px solid #63B5E3
            }
            }
   /deep/ .md-tab-bar-ink{
      display none
    }
    .qhbg{
      margin-top -55px
    }
}
.balan{
    min-width: 110px;
    }
    .balance{
      min-width: 219px;
    }
    .balance_radius{
      width: 94%;
      // height: 800px
      overflow: hidden;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      margin: 40px auto;
    }
    .balance_radius .tr_background{
      background-color: #3196F1;
      color: #eeeeee;
      border-left: .5px solid #3196f1;
      th {
          text-align: center;
      }
    }
    .balance_radius .balance_age{
      // background-color: #3196f1;
      // color: #ffffff;
      border: .5px solid #cccccc;
      text-align: center;
      padding: 10px 0 10px 0

    }
    .nodata{
      margin 50px auto
      p {
        text-align: center;
      }
    }
</style>
